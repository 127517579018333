import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { CommonService } from '../../services/common.service';
import { Meta, Title } from '@angular/platform-browser';
import { SyncService } from '../../services/sync.service';
import { CikkService } from '../../services/cikk.service';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { UserService } from '../../services/user.service';
import { PasswordStrengthValidator } from '../../password.validator';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Constants } from '../../constants';
import { HttpClient } from '@angular/common/http';
import { NgModel } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-reg',
  templateUrl: './reg.component.html',
  styleUrls: ['./reg.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class RegComponent implements OnInit {

	params: any;
  	szavak: any;
  	siteurl: any;
	menu: any;
	localStorage: Storage;
	lang: any = "hu";
	shopcode: any;
	alias: any;
	reg: any;
	cikk: any;
	magan: any;
	viszont: any;
	token: any;
	error: any = true;
	user: any;
	hiba: any = "";
	forgot: any;
	validpw: any = false;
	submitted: any = false;
	up: any = false;
	loading: any = false;
	cikk2: any;
	szall_1_show: any = true;
	szall_2_show: any = false;
	szall_3_show: any = false;
	szall_4_show: any = false;
	szall_5_show: any = false;
	regtype: any;
	adat_ny: any;
	aszf: any;
	szam_szall_ua : any = false;
	hozza: any;
	favicon: any;
	seotitle: any;

	regform = new FormGroup({
		files: new FormControl('', []),
		users_id: new FormControl('', []),
		szall_kernev: new FormControl('', []),
		tel: new FormControl('', [
			Validators.required
	  	]),
    	email: new FormControl('', [Validators.email,Validators.required]),
		kapcsa: new FormControl('',[
			Validators.required
		]),
		pw: new FormControl('',[
			Validators.required,
			Validators.minLength(6),
			PasswordStrengthValidator
		]),
		pw2: new FormControl('',[
			Validators.required,
			Validators.minLength(6),
			PasswordStrengthValidator
		]),
		szam_irsz: new FormControl('', [
			Validators.required
		]),
		szam_varos: new FormControl('', [
			Validators.required
		]),
		szam_utca: new FormControl('', [
			Validators.required
		]),
		szall_1_mukodes: new FormControl('', []),
		szall_1_mukodes_src: new FormControl('', []),
		szall_2_cegnev: new FormControl('', []),
		szall_2_irsz: new FormControl('', []),
		szall_2_varos: new FormControl('', []),
		szall_2_utca: new FormControl('', []),
		szall_2_mukodes: new FormControl('', []),
		szall_2_mukodes_src: new FormControl('', []),
		szall_3_cegnev: new FormControl('', []),
		szall_3_irsz: new FormControl('', []),
		szall_3_varos: new FormControl('', []),
		szall_3_utca: new FormControl('', []),
		szall_3_mukodes: new FormControl('', []),
		szall_3_mukodes_src: new FormControl('', []),
		szall_4_cegnev: new FormControl('', []),
		szall_4_irsz: new FormControl('', []),
		szall_4_varos: new FormControl('', []),
		szall_4_utca: new FormControl('', []),
		szall_4_mukodes: new FormControl('', []),
		szall_4_mukodes_src: new FormControl('', []),	
		szall_5_cegnev: new FormControl('', []),
		szall_5_irsz: new FormControl('', []),
		szall_5_varos: new FormControl('', []),
		szall_5_utca: new FormControl('', []),
		szall_5_mukodes: new FormControl('', []),
		szall_5_mukodes_src: new FormControl('', []),
		aszf: new FormControl('', [
			Validators.required
		]),
		any: new FormControl('', [
			Validators.required
		]),
		hozzaj: new FormControl('', [
			//Validators.required
		]),
	});

	addForm(){
		if(this.alias === 'maganszemely'){
			this.regform.registerControl('veznev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('kernev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szam_veznev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szam_kernev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szall_veznev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szall_kernev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szall_irsz',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szall_varos',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szall_utca',new FormControl('', [
				Validators.required
			]));
		}
		if(this.alias === 'viszontelado'){
			this.regform.addControl('cegnev',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('kapcs',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('adoszam',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('szall_0_mukodes',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('szall_0_mukodes_src',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('szam_ado',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('szam_cegnev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szall_1_irsz',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szall_1_varos',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szall_1_utca',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('szall_1_cegnev',new FormControl('', [
				Validators.required
			]));
		}
	}

	mustMatch() {
		console.log("ezlefut");
		if(this.regform.controls.pw.value != '' && this.regform.controls.pw2.value != ''){
			console.log("nem ures");
			if(this.regform.controls.pw2.value === this.regform.controls.pw.value){
				console.log("egyezik");
				this.validpw = true;
				return true;
			}
			else{
				console.log("nem egyezik");
				this.validpw = false;
				return false;
			}
		}
		else{
			console.log("ures");
			this.validpw = false;
			return false;
		}
	}

	egyezik(){
		if(this.szam_szall_ua === false){
			this.szam_szall_ua = true;
			if(this.alias === "maganszemely"){
				this.regform.controls.szall_kernev.setValue(this.regform.controls.szam_kernev.value);
				this.regform.controls.szall_veznev.setValue(this.regform.controls.szam_veznev.value);
				this.regform.controls.szall_irsz.setValue(this.regform.controls.szam_irsz.value);
				this.regform.controls.szall_varos.setValue(this.regform.controls.szam_varos.value);
				this.regform.controls.szall_utca.setValue(this.regform.controls.szam_utca.value);
			}
			if(this.alias === 'viszontelado'){
				this.regform.controls.szall_1_cegnev.setValue(this.regform.controls.szam_cegnev.value);
				this.regform.controls.szall_1_irsz.setValue(this.regform.controls.szam_irsz.value);
				this.regform.controls.szall_1_varos.setValue(this.regform.controls.szam_varos.value);
				this.regform.controls.szall_1_utca.setValue(this.regform.controls.szam_utca.value);
			}
		}
		else{
			this.szam_szall_ua = false;
		}
	}

	onFileChange(event){
		this.up = true;
		let name = event.target.attributes.formcontrolname.value;
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			if(name === "szall_0_mukodes"){
				this.regform.patchValue({
					szall_0_mukodes_src: file
			  	});
			}
			if(name === "szall_1_mukodes"){
				this.regform.patchValue({
					szall_1_mukodes_src: file
			  	});
			}
			if(name === "szall_2_mukodes"){
				this.regform.patchValue({
					szall_2_mukodes_src: file
			  	});
			}
			if(name === "szall_3_mukodes"){
				this.regform.patchValue({
					szall_3_mukodes_src: file
			  	});
			}
			if(name === "szall_4_mukodes"){
				this.regform.patchValue({
					szall_4_mukodes_src: file
			  	});
			}
			if(name === "szall_5_mukodes"){
				this.regform.patchValue({
					szall_5_mukodes_src: file
			  	});
			}
		}
	}
	
	onSubmit() {
		this.error = true;
		this.submitted = true;
		this.mustMatch();
		console.log("1");
		if(this.alias === 'maganszemely'){
			console.log("2");
		}
		if(this.alias === 'viszontelado'){
			console.log("3");
			if(this.regform.controls['szall_0_mukodes_src'].value === ''){
				console.log("4");
				this.error = false;
				this.dialog.open(DialogComponent, {
					data: {
						title: 'Hiba',
						text: 'Működési engedély feltöltése kötelező',
						gomb: 'bezárás'
					}
				});
			}
		}
		Object.keys(this.regform.controls).forEach(key => {
			if(this.regform.controls[key].errors != null){
				console.log(key);
				this.error = false;
			}
		});
		if(this.validpw === false){
			this.error = false;
			this.dialog.open(DialogComponent, {
				data: {
					title: 'Hiba',
					text: 'Hibás jelszó',
					gomb: 'bezárás'
				}
			});
		}
		if(this.alias === 'viszontelado'){
			console.log("6");
			if(this.regform.controls['adoszam'].value != this.regform.controls['szam_ado'].value){
				console.log("7");
				this.error = false;
				this.dialog.open(DialogComponent, {
					data: {
						title: 'Hiba',
						text: 'Két adószám nem egyezik',
						gomb: 'bezárás'
					}
				});
			}
		}
		/*if (isNaN(this.regform.controls['tel'].value)) {
			this.error = false;
			this.dialog.open(DialogComponent, {
				data: {
					title: 'Hiba',
					text: 'A telefonszám szóközt tartalmaz',
					gomb: 'bezárás'
				}
			});
		}*/
    	if (this.error === false || this.validpw === false) {
			window.scroll({ 
				top: 0, 
				left: 0, 
				behavior: 'smooth' 
			});
      		return;
    	}
    	else{
			console.log("9");
			this.loading = true;
			if(this.up === true){
				console.log("10");
				const formData = new FormData();
				formData.append('szall_0_mukodes', this.regform.controls.szall_0_mukodes_src.value);
				formData.append('szall_1_mukodes', this.regform.controls.szall_1_mukodes_src.value);
				formData.append('szall_2_mukodes', this.regform.controls.szall_2_mukodes_src.value);
				formData.append('szall_3_mukodes', this.regform.controls.szall_3_mukodes_src.value);
				formData.append('szall_4_mukodes', this.regform.controls.szall_4_mukodes_src.value);
				formData.append('szall_5_mukodes', this.regform.controls.szall_5_mukodes_src.value);
				this.http.post('https://www.tassi.hu/rest/upload.php', formData)
				.subscribe(res => {
					console.log(res);
					if(res[0]){
						this.regform.patchValue({
							files: res,
							users_id: res['users_id']
						});
						this.userService.reg(this.token, btoa(unescape(encodeURIComponent(JSON.stringify(this.regform.value)))),"hu",this.up, this.alias).subscribe((data: any) => {
							if(data.resp === 'false'){
								this.dialog.open(DialogComponent, {
									data: {
										title: 'Sikertelen regisztráció',
										text: 'Ezzel az email címmel már létezik felhasználó',
										gomb: 'bezárás'
									}
								});
							}
							else{
								this.submitted = false;
								this.regform.reset();
								this.loading = false;
								if(this.alias === 'maganszemely'){
									this.route.navigate(["sikeres-regisztracio-magan"]);
								}
								if(this.alias === 'viszontelado'){
									this.route.navigate(["sikeres-regisztracio-ceg"]);
								}
							}
						});
					}
					else{
						this.dialog.open(DialogComponent, {
							data: {
								title: 'Hiba',
								text: 'Működési engedély feltöltése sikertelen, túl nagy a fájl mérete',
								gomb: 'bezárás'
							}
						});
					}
				});
			}
			else{
				console.log("11");
				this.userService.reg(this.token, btoa(unescape(encodeURIComponent(JSON.stringify(this.regform.value)))),"hu",this.up, this.alias).subscribe((data: any) => {
					if(data.resp === 'false'){
						this.dialog.open(DialogComponent, {
							data: {
								title: 'Sikertelen regisztráció',
								text: 'Ezzel az email címmel már létezik felhasználó',
								gomb: 'bezárás'
							}
						});
					}
					else{
						this.submitted = false;
						this.regform.reset();
						this.loading = false;
						if(this.alias === 'maganszemely'){
							this.route.navigate(["sikeres-regisztracio-magan"]);
						}
						if(this.alias === 'viszontelado'){
							this.route.navigate(["sikeres-regisztracio-ceg"]);
						}
					}
				});
			}
		}
	}

	get f() { return this.regform.controls; }
	
	constructor(private http: HttpClient, private userService: UserService, public dialog: MatDialog, private route: Router, private cikkService: CikkService, public sync: SyncService,private title: Title, private meta: Meta, private router: ActivatedRoute, private commonService: CommonService) {
    	this.router.paramMap.subscribe(params => {
      		this.getData();
		});
		this.localStorage = window.localStorage;
	}

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}

	resolved(captchaResponse: string) {
    	this.token = captchaResponse;
	}

	getData() {
    	this.router.paramMap.subscribe((params:any) => {
      		this.params = params.params;
		});
		if(Object.keys(this.params).length > 0){
			this.alias = this.params.id;
		}
		else{
			this.alias = false;
		}
		if(this.alias != "maganszemely" && this.alias != "viszontelado"){
			//this.route.navigate(["/404"]);
		}
		else{
			this.addForm();
			this.cikkService.getcikk(this.params.id,this.lang).subscribe((data: {}) => {
				this.cikk = data;
			});
			this.commonService.getword(this.lang,"magan-viszont-kotelezo-veznev-kernev-cegnev-kapcsnev-tel-email-pw-pw2-hibas-minkar-nagysz-ketj-adoszam-muke-tallozas-szamlazasi_cim-irsz-varos-utcahsz-szallitasi_cim-szallitasi_azonos_szamlazasival-cim-elfogadom-reg").subscribe((data: {}) => {
				this.szavak = data;
				if(this.alias === "maganszemely"){
					this.regtype = this.szavak[0];
				}
				if(this.alias === "viszontelado"){
					this.regtype = this.szavak[1];
				}
			});
		}
	}
	  
	openCim(id){
		if(id === 1){
			if(this.szall_1_show === true){
				this.szall_1_show = false;
			}
			else{
				this.szall_1_show = true;
			}
		}
		if(id === 2){
			if(this.szall_2_show === true){
				this.szall_2_show = false;
			}
			else{
				this.szall_2_show = true;
			}
		}
		if(id === 3){
			if(this.szall_3_show === true){
				this.szall_3_show = false;
			}
			else{
				this.szall_3_show = true;
			}
		}
		if(id === 4){
			if(this.szall_4_show === true){
				this.szall_4_show = false;
			}
			else{
				this.szall_4_show = true;
			}
		}
		if(id === 5){
			if(this.szall_5_show === true){
				this.szall_5_show = false;
			}
			else{
				this.szall_5_show = true;
			}
		}
	}

  	ngOnInit(): void {
		//this.addForm();
		if(Object.keys(this.params).length === 0){
			this.cikkService.getcikk(this.route.url.substr(1),this.lang).subscribe((data: {}) => {
				this.cikk = data;
			});
		}
		else{
			//this.alias = this.params.id;
		}
		this.commonService.getword(this.lang,"magan-viszont-kotelezo-veznev-kernev-cegnev-kapcsnev-tel-email-pw-pw2-hibas-minkar-nagysz-ketj-adoszam-muke-tallozas-szamlazasi_cim-irsz-varos-utcahsz-szallitasi_cim-szallitasi_azonos_szamlazasival-cim-elfogadom-reg").subscribe((data: {}) => {
			this.szavak = data;
		});
		this.commonService.getmodul(this.lang,"footer5").subscribe((data: {}) => {
			this.hozza = data[0];
		});
		this.commonService.favicon().subscribe((data: any) => {
			this.favicon = data.favicon;
		});
		this.commonService.seotitle().subscribe((data: any) => {
			this.seotitle = data.title;
		});
		this.commonService.siteurl().subscribe((data: any) => {
			this.siteurl = data.siteurl;
		});
		this.commonService.menuroww("41-74-75-48-47",this.lang).subscribe((data: {}) => {
			this.reg = data[0];
			let kepnev = this.favicon;
			let url = this.siteurl+this.reg.alias;
			this.commonService.seo(this.seotitle,'website',this.reg.seotitle,this.reg.desc,url,kepnev);
			this.magan = data[1];
			this.viszont = data[2];
			this.adat_ny = data[3];
			this.aszf = data[4];
	  	});
	}
}
