<div class="reg login">
	<h1 *ngIf="login">{{login.title}}</h1>
	<dynamic-html *ngIf="cikk" class="cont" [content]="cikk.text"></dynamic-html>
	<form [formGroup]="loginform" class="login_modul" (ngSubmit)="onSubmit()">
		<div class="form-group col-xs-12 col-sm-12 col-md-12">
			<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
				<div *ngIf="f.email.errors.required">E-mail cím kötelező</div>
				<div *ngIf="f.email.errors.email">Hibás e-mail cím formátum</div>
			</div>
			<input type="email" formControlName="email" required placeholder="E-mail cím"/>
			<label>E-mail cím</label>
		</div>
		<div class="form-group col-xs-12 col-sm-12 col-md-12">
			<div *ngIf="submitted && f.pw.errors" class="invalid-feedback">
				<div *ngIf="f.pw.errors.required">Jelszó kötelező</div>
			</div>
			<input type="password" formControlName="pw" required placeholder="Jelszó"/>
			<label>Jelszó</label>
		</div>			
		<re-captcha [ngClass]="{'invalidated': submitted}" required formControlName="gcaptcha" (resolved)="resolved($event)" siteKey="6LdvVcUZAAAAABLCyf43sJvYw4KNrLjg-KdSx5Hq"></re-captcha>
		<a *ngIf="forgot" class="forgotlink" routerLink="/{{forgot.alias}}"><span>{{forgot.title}}</span></a>
		<button type="submit" name="kapcs_kuld" class="kapcs_kuld" value="Belépés"><span *ngIf="login">{{login.title}}</span></button>
		<div class="reg_mod">
			<span *ngIf="szavak">{{szavak[0].cim}}</span>
			<a *ngIf="reg" class="reglink" routerLink="/{{reg.alias}}"><span>{{reg.title}}</span></a>
		</div>
	</form>
</div>
