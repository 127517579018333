import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KatService } from '../../services/kat.service';
import { ProdService } from '../../services/prod.service';
import { CommonService } from '../../services/common.service';
import { SyncService } from '../../services/sync.service';
import { Meta, Title } from '@angular/platform-browser';
import { FormGroup, FormControl} from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KosarbaComponent } from '../../components/kosarba/kosarba.component';
import { NumberFormatPipe } from '../../number.pipe';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-katalogus',
  templateUrl: './katalogus.component.html',
  styleUrls: ['./katalogus.component.sass', '../../components/cikk/cikk.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class KatalogusComponent implements OnInit {

  	szavak: any;
  	katmenu: any;
  	siteurl: any;
	lang: any = "hu";
	cikk: any;
	szuro_check: any = false;
	szurostring: any;
	katList: any = [];
	closed: any = true;
	shopcode: any;
	localStorage: any;
	params: any;
	alkat: any;
	fokat: any;
	url: any;
	lap: any = 1;
	currentKat: any = "";
	fullUrl:any = "";
	order:any;
	favicon: any;
	seotitle: any;
	prod: any = [];
	szavak2: any;
	n: any;
	van_tovabb: boolean = true;
	userInfo: any;
	user_tipus: any;
	katleir: any;
	moreplus: boolean = false;
	prod2: any = [];

	szuroform = new FormGroup({
		nev: new FormControl('', []),
		kat: new FormControl('', []),
		ar: new FormControl([0,2999990]),
		kisz: new FormControl('', []),
		evjarat: new FormControl('', []),
		csom: new FormControl('', []),
		er_ido: new FormControl([0,100]),
		alk_fok: new FormControl([0,100]),
		limit: new FormControl('', []),
		prodlength: new FormControl(),
		from: new FormControl(0),
		leng: new FormControl('', []),
		order: new FormControl([1]),
		selectedEvjarat: new FormControl('', []),
		selectedCsom: new FormControl('', []),
		selectedKisz: new FormControl('', [])
	});

  	constructor(public user: UserService, public dialog: MatDialog, private rtr: Router, public sync: SyncService, private router: ActivatedRoute, private katService: KatService, private prodService: ProdService, private commonService: CommonService) {
		//this.rtr.routeReuseStrategy.shouldReuseRoute = () => false;
		this.localStorage = window.localStorage;
		this.router.paramMap.subscribe((params:any) => {
			this.getParams(params);
			//this.ngOnInit();
		});
		this.user.userInfo$.subscribe((userInfo:any) => {
			this.userInfo = userInfo;
			this.user_tipus = this.userInfo.users_tipus;
		});
		this.sync.thread_id$.subscribe((thread_id:any) => {
			//this.katList.length = 0;
			if(
				thread_id.nev!= '' || 
				thread_id.kisz != '' || 
				thread_id.evjarat != '' ||
				thread_id.csom != '' ||
				thread_id.limit!= '' ||
				thread_id.ar[0] > 0 ||
				thread_id.ar[1] < 2999990 ||
				thread_id.er_ido[0] > 0 ||
				thread_id.er_ido[1] < 100 ||
				thread_id.alk_fok[0] > 0 ||
				thread_id.alk_fok[1] < 100 ||
				thread_id.selectedEvjarat.length > 0 ||
				thread_id.selectedEvjarat != '' ||
				thread_id.selectedCsom.length > 0 ||
				thread_id.selectedCsom != '' ||
				thread_id.selectedKisz.length > 0 ||
				thread_id.selectedKisz != ''
			){
				this.szuro_check = true;
			}
			else{
				this.szuro_check = false;
			}
			this.szuroform.patchValue({
				nev: thread_id.nev,
				ar: thread_id.ar,
				kisz: thread_id.kisz,
				evjarat: thread_id.evjarat,
				csom: thread_id.csom,
				er_ido: thread_id.er_ido,
				alk_fok: thread_id.alk_fok,
				limit: thread_id.limit,
				selectedEvjarat: thread_id.selectedEvjarat,
				selectedCsom: thread_id.selectedCsom,
				selectedKisz: thread_id.selectedKisz,
				order: this.order
			});
			this.szurostring = btoa(unescape(encodeURIComponent(JSON.stringify(this.szuroform.value))));
			this.prod2 = [];
			this.prodService.prodszuro(0,9,this.szurostring, this.user_tipus).subscribe((data: {}) => {
				this.prod2 = data[0];
			});
		});
	}

	async delay(ms: number) {
		await new Promise(resolve => setTimeout(()=>resolve(), ms)).then(()=>this.empty());
	}

	empty(){

	}

	more(){
		this.n++;
		this.localStorage.setItem(this.shopcode+"_num", this.n);
		let posY = window.scrollY;
		let to = 9*this.n;
		let old_prod = this.prod;
		if(this.moreplus === true){
			this.prodService.prodszuro(this.n,9,this.szurostring, this.user_tipus).subscribe((data: {}) => {
				if(data[0].length < 9){
					this.van_tovabb = false;
				}
				for(let item of data[0]){
					if(old_prod.find(x => x.id === item.id)){

					}
					else{
						this.prod.push(item);	
					}
				}
				//this.rendez();
			});
		}
		else{
			this.prodService.prodszuro(1,9,this.szurostring, this.user_tipus).subscribe((data: {}) => {
				if(data[0].length < 9){
					this.van_tovabb = false;
				}
				this.prod = this.prod2;
				for(let item of data[0]){
					if(this.prod2.find(x => x.id === item.id)){

					}
					else{
						this.prod.push(item);	
					}
				}
				//this.rendez();
			});
			this.moreplus = true;
		}
		this.delay(500).then(any=>{
			window.scrollTo(0,posY)
		});
	}



	getUnique(arr, comp) {
		const unique =  arr.map(e => e[comp]).map((e, i, final) => final.indexOf(e) === i && i).filter((e) => arr[e]).map(e => arr[e]);
		return unique;
	}

	openRend(){
		this.closed = false;
	}

	getSzuro(){
		this.prod2 = [];
		this.szurostring = btoa(unescape(encodeURIComponent(JSON.stringify(this.szuroform.value))));
		this.prodService.prodszuro(0,9,this.szurostring, this.user_tipus).subscribe((data: {}) => {
			this.prod2 = data[0];
		});
		if(this.localStorage.getItem(this.shopcode+"_szuro") != null || this.n > 1){
			this.szuro_check = true;
		}
		else{
			this.szuro_check = false;
		}
	}

	getParams(params){
		if(this.localStorage.getItem(this.shopcode+"_szuro") != null){
			let formItem = JSON.parse(this.localStorage.getItem(this.shopcode+"_szuro"));	
			this.szuroform.patchValue({
				nev: formItem.nev,
				ar: formItem.ar,
				kisz: formItem.kisz,
				evjarat: formItem.evjarat,
				csom: formItem.csom,
				er_ido: formItem.er_ido,
				alk_fok: formItem.alk_fok,
				limit: formItem.limit,
				selectedEvjarat: formItem.selectedEvjarat,
				selectedCsom: formItem.selectedCsom,
				selectedKisz: formItem.selectedKisz
			});
		}
		if(this.localStorage.getItem(this.shopcode+"_u") != null){
			this.userInfo = JSON.parse(this.localStorage.getItem(this.shopcode+"_u"));
			this.user_tipus = this.userInfo.users_tipus;
		}
		else{
			this.user_tipus = 'privat';
		}
		this.url = "";
		this.params = params.params;
		let newUrl = "";
		for (let paramObj of Object.values(this.params)) {
			newUrl = newUrl+"/"+paramObj;
		}
		this.fullUrl = newUrl;
		this.commonService.menuroww("17",this.lang).subscribe((data: {}) => {
			this.katmenu = data[0];
			if(this.params.fokat != undefined){
				this.katService.katrow(this.params.fokat,0,this.lang).subscribe((data: {}) => {
					this.fokat = data[0];
					if(data[0] != undefined){
						this.url = this.fokat.alias;
						if(this.params.alkat != undefined){
							this.katService.katrow(this.params.alkat,this.fokat.termek_kategoriak_id,this.lang).subscribe((data2: any) => {
								this.alkat = data2;
								if(data2.length > 0){
									let url = this.siteurl+this.katmenu.alias+this.fullUrl;
									let kepnev = this.siteurl+"/vision/cms/uploaded_images/category/"+this.alkat[0].termek_kategoriak_kepnev;
									this.commonService.seo(this.seotitle,'website',this.alkat[0].title,this.alkat[0].desc,url,kepnev);
									this.commonService.schema("alkategory","hu",this.params.alkat).subscribe((data2: any) => {
										this.commonService.insertSchema(data2);
									});
									this.szuroform.patchValue({
										kat: this.alkat[0].termek_kategoriak_id,
										order: this.params.order,
										from: this.params.lap
									});
									this.currentKat = this.alkat[0].termek_kategoriak_id;
									this.katleir = this.alkat[0].leiras;
									this.order = this.params.order;
									this.lap = this.params.lap;
									this.n = Number(this.lap);
									this.url = this.fokat.alias+"/"+this.alkat[0].alias;
									this.getSzuro();
								}
								else{
									this.commonService.schema("focategory","hu",this.fokat.alias).subscribe((data2: any) => {
										this.commonService.insertSchema(data2);
									});
									let url = this.siteurl+this.katmenu.alias+this.fullUrl;
									let kepnev = this.siteurl+"/vision/cms/uploaded_images/category/"+this.fokat.termek_kategoriak_kepnev;
									this.commonService.seo(this.seotitle,'website',this.fokat.title,this.fokat.desc,url,kepnev);
									this.szuroform.patchValue({
										kat: this.fokat.termek_kategoriak_id,
										order: this.params.alkat,
										from: this.params.order
									});
									this.order = this.params.alkat;
									this.lap = this.params.order;
									this.n = Number(this.lap);
									this.currentKat = this.fokat.termek_kategoriak_id;
									this.katleir = this.fokat.leiras;
									this.getSzuro();
								}
							});
						}
						else{
							this.commonService.schema("focategory","hu",this.fokat.alias).subscribe((data2: any) => {
								this.commonService.insertSchema(data2);
							});
							let url = this.siteurl+this.katmenu.alias+this.fullUrl;
							let kepnev = this.siteurl+"/vision/cms/uploaded_images/category/"+this.fokat.termek_kategoriak_kepnev;
							this.commonService.seo(this.seotitle,'website',this.fokat.title,this.fokat.desc,url,kepnev);
							this.alkat = false;
							this.getSzuro();
						}
					}
					else{
						//this.rtr.navigate(["/404"]);
						this.commonService.seo(this.seotitle,'website',this.katmenu.title,this.katmenu.desc,this.siteurl,this.favicon);
						this.fokat = false;
						this.alkat = false;
						this.currentKat = "0";
						this.order = this.params.fokat;
						this.lap = this.params.alkat;
						this.n = Number(this.lap);
						this.szuroform.patchValue({
							kat: 0,
							order: this.order,
							from: this.params.alkat
						});
						this.getSzuro();
					}
				});
			}
			else{
				this.fokat = false;
				this.alkat = false;
				this.currentKat = "0";
				this.order = "abc";
				this.commonService.schema("cikk","hu",this.katmenu.title).subscribe((data2: any) => {
					this.commonService.insertSchema(data2);
				});
				this.szuroform.patchValue({
					kat: 0,
					order: this.order,
					from: 1
				});
				this.getSzuro();
				this.fullUrl = "italok/abc/";
				this.commonService.seo(this.seotitle,'website',this.katmenu.title,this.katmenu.desc,this.siteurl,this.favicon);
			}
		});
	}

	/*katParam(params){
		this.url = "";
		this.params = params.params;
		let newUrl = "";
		for (let paramObj of Object.values(this.params)) {
			newUrl = newUrl+"/"+paramObj;
		}
		this.fullUrl = newUrl;
		this.commonService.menuroww("17",this.lang).subscribe((data: {}) => {
			this.katmenu = data[0];
			if(this.params.fokat != undefined){
				this.katService.katrow(this.params.fokat,0,this.lang).subscribe((data: {}) => {
					this.fokat = data[0];
					if(data[0] != undefined){
						this.url = this.fokat.alias;
						if(this.params.alkat != undefined){
							this.katService.katrow(this.params.alkat,this.fokat.termek_kategoriak_id,this.lang).subscribe((data2: any) => {
								this.alkat = data2;
								if(data2.length > 0){
									this.currentKat = this.alkat[0].termek_kategoriak_id;
									this.order = this.params.order;
								}
								else{
									this.currentKat = this.fokat.termek_kategoriak_id;
									this.order = this.params.alkat;
								}
								this.prodService.prodlist(this.currentKat,0,9,this.order,"hu",this.user_tipus).subscribe((data: {}) => {
									this.prod2 = data[0];
								});
							});
						}
					}
					else{
						this.fokat = false;
						this.alkat = false;
						this.currentKat = "0";
						this.order = this.params.fokat;
						this.prodService.prodlist(this.currentKat,0,9,this.order,"hu",this.user_tipus).subscribe((data: {}) => {
							this.prod2 = data[0];
						});
					}
				});
			}
			else{
				this.fokat = false;
				this.alkat = false;
				this.order = this.params.fokat;
				this.currentKat = "0";
				this.prodService.prodlist(this.currentKat,0,9,this.order,"hu",this.user_tipus).subscribe((data: {}) => {
					this.prod2 = data[0];
				});
			}
		});
	}*/

	kosarba(id,title,img,db,cikkszam,ara_magan,ara_ceg,lak_ar,lak_akcio){
		this.dialog.open(KosarbaComponent, {
			data: {
				title: title,
				img: img,
				id: id,
				darab: db,
				users_tipus: this.user_tipus,
				cikkszam: cikkszam,
				ara_magan: ara_magan,
				ara_ceg: ara_ceg,
				lak_ar: lak_ar,
				lak_akcio: lak_akcio
			}
		});
	}

  	ngOnInit(): void {
		this.commonService.favicon().subscribe((data: any) => {
			this.favicon = data.favicon;
		});
		this.commonService.seotitle().subscribe((data: any) => {
			this.seotitle = data.title;
		});
		this.commonService.siteurl().subscribe((data3: any) => {
			this.siteurl = data3.siteurl;
		});
		this.router.paramMap.subscribe((params:any) => {
			//this.getParams(params);
		});
		this.commonService.shopcode().subscribe((data: any) => {
			this.localStorage.removeItem(this.shopcode+"_num");
			this.shopcode = data.shop_code;
			if(this.localStorage.getItem(this.shopcode+"_u") != null){
				this.userInfo = JSON.parse(this.localStorage.getItem(this.shopcode+"_u"));
				this.user_tipus = this.userInfo.users_tipus;
			}
			else{
				this.user_tipus = 'privat';
			}
			/*if(this.localStorage.getItem(this.shopcode+"_szuro") != null){
				let formItem = JSON.parse(this.localStorage.getItem(this.shopcode+"_szuro"));
				
				this.szuro_check = true;
				this.szuroform.patchValue({
					nev: formItem.nev,
					ar: formItem.ar,
					kisz: formItem.kisz,
					evjarat: formItem.evjarat,
					csom: formItem.csom,
					er_ido: formItem.er_ido,
					alk_fok: formItem.alk_fok,
					limit: formItem.limit,
					selectedEvjarat: formItem.selectedEvjarat,
					selectedCsom: formItem.selectedCsom,
					selectedKisz: formItem.selectedKisz,
					order: formItem.order
				});
				this.szurostring = btoa(unescape(encodeURIComponent(JSON.stringify(this.szuroform.value))));
				this.prodService.prodszuro(0,9,this.szurostring, this.user_tipus).subscribe((data: {}) => {
					this.prod2 = data[0];
				});
			}
			else{
				this.szurostring = false;
				this.router.paramMap.subscribe((params:any) => {
					this.katParam(params);
				});
				this.szuro_check = false;
			}*/
		});
		this.commonService.getword(this.lang,"tovabb").subscribe((data: {}) => {
			this.szavak = data;
		});
		this.commonService.getword(this.lang,"ft-kosarba-menny_kedv-eredetiar-ar-akcios_szalag-uj_szalag-uvegtol").subscribe((data: {}) => {
			this.szavak2 = data;
	  	});
		this.commonService.getmodul(this.lang,"footer7").subscribe((data: {}) => {
			this.cikk = data[0];
		});
	}
}
