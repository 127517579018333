import { Injectable, Directive } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import{Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class KosarService {

	private kosar_id = new Subject<number>();
  	kosar_id$ = this.kosar_id.asObservable();

  	set_kosar_id(kosar_id: number) {
		this.kosar_id.next(kosar_id);
	}
	  
	private kosar_ossz = new Subject<number>();
  	kosar_ossz$ = this.kosar_ossz.asObservable();

  	set_kosar_ossz(kosar_ossz: number) {
		this.kosar_ossz.next(kosar_ossz);
	} 
	
	private kosar_szall_ossz = new Subject<number>();
	kosar_szall_ossz$ = this.kosar_szall_ossz.asObservable();

	set_kosar_szall_ossz(kosar_szall_ossz: number) {
		this.kosar_szall_ossz.next(kosar_szall_ossz);
  	}   
	
	constructor(private http: HttpClient) {}

  	public extractData(res: Response) {
		let body = res;
		return body || { };
  	}

  	public getszallmod(lang): Observable<any> {
		return this.http.get(Constants.endpoint+'cart/szallmod/'+lang).pipe(
		map(this.extractData));
	}
	  
	public naptar(tipus): Observable<any> {
		return this.http.get(Constants.endpoint+'cart/naptar/'+tipus).pipe(
		map(this.extractData));
  	}

  	public getfizmod(lang): Observable<any> {
		return this.http.get(Constants.endpoint+'cart/fizmod/'+lang).pipe(
		map(this.extractData));
  	}

  	public rend(szallar,kosar,user,lang, tipus): Observable<any> {
		const headers = { 'content-type': 'application/x-www-form-urlencoded' };
		let data = "rend%2" + szallar + "%2" + kosar + "%2" + user + "%2" + lang + "%2" + tipus;
		//let adat = btoa(JSON.stringify(data));
		let adat = btoa(unescape(encodeURIComponent(data)));
		//let adat = btoa(unescape(encodeURIComponent(JSON.stringify(data))));
		return this.http.post(Constants.endpoint2+"cartpost",adat,{'headers':headers}).pipe(
		map(this.extractData));
  	}

  	public kupon(kod,vegossz): Observable<any> {
		return this.http.get(Constants.endpoint+'cart/kupon/'+kod+"/"+vegossz).pipe(
		map(this.extractData));
  	}

  	public shopconfig(): Observable<any> {
		return this.http.get(Constants.endpoint+'cart/shopconfig/1').pipe(
		map(this.extractData));
  	}
}