import { Component, OnInit, VERSION, ViewEncapsulation, OnDestroy, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { MenuService } from '../../services/menu.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { ThrowStmt } from '@angular/compiler';
import { SyncService } from '../../services/sync.service';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { KosarService } from '../../services/kosar.service';
import { CookieService } from 'ngx-cookie-service';

declare let ga: Function;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  	menu: any;
 	localStorage: Storage;
	lang: any = "hu";
	shopcode: any;
	home: any = true;
	header2: any;
	status: boolean = false;
	error: any = true;
	termek: any;
	szavak: any;
	reg: any;
	kosar:any;
	login: any;
	face: any;
	insta: any;
	userInfo: any;
	adataim: any;
	kosar_id: any = Array();
	kosar_ossz: any = 0;
	popup: any;
	pop_closed: boolean = false;
	user_tipus: any;
	szuro_check: any;
	szurostring: any;
	prod: any;
	params: any;
	cVal: any;
	cVal2: any;
	nyito_van: boolean = false;
	cookie_text: any;
	cookie_pop: boolean = true;
	@ViewChild("menucheck") checkmenu: ElementRef;
	
	
  	constructor(private cK: CookieService,private kosarService: KosarService, public user: UserService, private sanitizer: DomSanitizer, private renderer: Renderer2, private router:Router, private route:ActivatedRoute, public sync: SyncService, private menuService: MenuService,private commonService: CommonService) {
		this.localStorage = window.localStorage;
		this.user.userInfo$.subscribe((userInfo:any) => {
			this.userInfo = userInfo;
			this.user_tipus = this.userInfo.users_tipus;
		});
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
			  ga('set', 'page', event.urlAfterRedirects);
			  ga('send', 'pageview');
			}
		});
		this.kosarService.kosar_id$.subscribe((kosar_id:any) => {
			this.kosar_id = kosar_id;
			this.commonService.shopcode().subscribe((data: any) => {
				this.shopcode = data.shop_code;
				if(this.localStorage.getItem(this.shopcode+"_kosar") != null){
					this.kosar_id = JSON.parse(this.localStorage.getItem(this.shopcode+"_kosar"));
				}
			});
			this.kosar_ossz = 0;
			for(let item of this.kosar_id){
				if(item.darab2 > 0){
					item.darab = item.darab2;
				}
				this.kosar_ossz = this.kosar_ossz + item.darab;
			}
		});
		this.sync.thread_id$.subscribe((thread_id:any) => {
			//this.katList.length = 0;
			this.szuro.patchValue({
				nev: thread_id.nev
			});
			this.szurostring = btoa(unescape(encodeURIComponent(JSON.stringify(this.szuro.value))));
			this.prod = [];
		});
		this.navcheck();
	}

	onfilter(){

	}
	previousUrl: any;
	currentUrl: any;

	navcheck(){
		this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
				// Show loading indicator
				this.commonService.removeStructuredData();
            }
            if (event instanceof NavigationEnd) {
				this.cVal = this.cK.get('tassi-nyito');
				if(this.cVal === 'true'){
					this.nyito_van = false;
				}
				else{
					this.nyito_van = true;
				}
				this.cVal2 = this.cK.get('tassi-cookie');
				if(this.cVal2 === 'true'){
					this.cookie_pop = false;
				}
				else{
					this.cookie_pop = true;
				}
				// Hide loading indicator
				let url_segments = event.url.split("/");
				if(url_segments[1] != 'italok' && url_segments[1] != 'shop'){
					this.szuro.controls.nev.setValue("");
					this.commonService.shopcode().subscribe((data: any) => {
						this.shopcode = data.shop_code;
						this.localStorage.removeItem(this.shopcode+"_szuro");
						this.localStorage.removeItem(this.shopcode+"_subkat");
					});
				}
				this.router.events.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => this.route),
				map(route => {
				  while (route.firstChild) route = route.firstChild
				  return route
				}),
				filter(route => route.outlet === 'primary'),mergeMap(route => route.data)).subscribe((data:any) =>{
					if(data.szuro != 'true'){
						//this.sync.set_thread_id(0);
						this.szuro.controls.nev.setValue("");
						this.commonService.shopcode().subscribe((data: any) => {
							this.shopcode = data.shop_code;
							this.localStorage.removeItem(this.shopcode+"_szuro");
							this.localStorage.removeItem(this.shopcode+"_subkat");
						});
					}
					if(data.home === "true"){
						this.home = true;
						this.renderer.addClass(document.body, 'home');
						this.renderer.removeClass(document.body, 'al');
					}
					else{
						this.home = false;
						this.renderer.removeClass(document.body, 'home');
							this.renderer.addClass(document.body, 'al');
					}
					this.checkmenu.nativeElement.checked = false;
				});
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
				
            }
        });
	}

	pop_close(){
		this.pop_closed = true;
	}

	cookie_close(){
		this.cookie_pop = false;
		let cE = new Date();
		cE.setDate(cE.getDate() + 100000000000000000000000);
		this.cK.set('tassi-cookie','true',cE);
	}

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}

	nev_ures(){
		this.szuro.controls.nev.setValue("");
	}

	szuro = new FormGroup({
		nev: new FormControl('', []),
		kat: new FormControl('',[]),
		ar: new FormControl([0,2999990]),
		evjarat: new FormControl('',[]),
		csom: new FormControl('',[]),
		kisz: new FormControl('',[]),
		er_ido: new FormControl([0,100]),
		alk_fok: new FormControl([0,100]),
		limit: new FormControl('', []),
		prodlength: new FormControl('',[]),
		from: new FormControl([0]),
		order: new FormControl("abc"),
		selectedKat: new FormControl('',[]),
		selectedCsom: new FormControl('',[]),
		selectedKisz: new FormControl('',[]),
		selectedEvjarat: new FormControl('',[])
	});
	  
	get f() { return this.szuro.controls; }

	headSearchSubmit() {
		this.localStorage.removeItem(this.shopcode+"_szuro");
		this.localStorage.removeItem(this.shopcode+"_subkat");
		this.localStorage.setItem(this.shopcode+"_szuro", JSON.stringify(this.szuro.value));
		this.sync.set_thread_id(this.szuro.value);
		this.router.navigate([this.termek.alias+"/abc/1"], { state: { szuro_submit: true } }).then(() => {
			window.location.reload();
		});
  	}

	open(){
		this.status = !this.status;       
	}

	openSubMenu(event){
		const checked = event.target.checked;
		this.menu.forEach((item:any) => {
			if(item.id === Number(event.target.value)){
				item.selected = true;
			}
			else{
				item.selected = false;
			}
		});
	}

	katSearch = new FormGroup({
    	kat: new FormControl('', [])
	});

	katSzur(id) {
		this.katSearch.patchValue({
			katmenu: id
		});
		this.commonService.shopcode().subscribe((data: any) => {
			this.shopcode = data.shop_code;
			this.localStorage.setItem(this.shopcode+"_szur", JSON.stringify(this.katSearch.value));
      		this.commonService.szuro(btoa(unescape(encodeURIComponent(JSON.stringify(this.katSearch.value)))),this.lang).subscribe((data: any) => {
				this.router.navigate([this.termek.alias]);
			});
		});
	  }
	  
	logout(){
		this.user.logout(btoa(unescape(encodeURIComponent(JSON.stringify(this.userInfo))))).subscribe((data: any) => {
			this.userInfo = false;
			this.localStorage.removeItem(this.shopcode+"_u");
			this.localStorage.removeItem(this.shopcode+"_kosar");
			//this.kosar_id = false;
			this.kosar_ossz = 0;
			this.user.set_userInfo(false);
			this.router.navigate(['/']);
		});
	}

  	ngOnInit(): void {
		this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd)
		).subscribe((event: NavigationEnd) => {
		   this.previousUrl = this.currentUrl;
		   this.commonService.setPreviousUrl(this.previousUrl);
		   this.currentUrl = event.url;
		});
		this.cVal = this.cK.get('tassi-nyito');
		if(this.cVal === 'true'){
			this.nyito_van = false;
		}
		else{
			this.nyito_van = true;
		}
		this.cVal2 = this.cK.get('tassi-cookie');
		if(this.cVal2 === 'true'){
			this.cookie_pop = false;
		}
		else{
			this.cookie_pop = true;
		}
		//this.navcheck();
		this.menuService.getmenu(this.lang,"4","0").subscribe((data: {}) => {
			this.menu = data;
		});
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			map(() => this.route),
			map(route => {
			  while (route.firstChild) route = route.firstChild
			  return route
			}),
		filter(route => route.outlet === 'primary'),mergeMap(route => route.data)).subscribe((data:any) =>{
			if(data.szuro != 'true'){
				this.szuro.controls.nev.setValue("");
				this.commonService.shopcode().subscribe((data: any) => {
					this.shopcode = data.shop_code;
					this.localStorage.removeItem(this.shopcode+"_szuro");
					this.localStorage.removeItem(this.shopcode+"_subkat");
				});
			}
			if(data.home === "true"){
				this.home = true;
				this.renderer.addClass(document.body, 'home');
				this.renderer.removeClass(document.body, 'al');
			}
			else{
				this.home = false;
				this.renderer.removeClass(document.body, 'home');
					this.renderer.addClass(document.body, 'al');
			}
			this.checkmenu.nativeElement.checked = false;
		});
		this.commonService.menuroww("17-41-40-80-77",this.lang).subscribe((data: {}) => {
			this.termek = data[0];
			this.reg = data[1];
			this.login = data[2];
			this.kosar = data[3];
			this.adataim = data[4];
		});
		this.commonService.shopcode().subscribe((data: any) => {
			if(this.localStorage.getItem(this.shopcode+"_szuro") != null){
				let formItem = JSON.parse(this.localStorage.getItem(this.shopcode+"_szuro"));
				this.szuro.patchValue({
					nev: formItem.nev
				});
			}
			this.shopcode = data.shop_code;
			if(this.localStorage.getItem(this.shopcode+"_u") != null){
				this.userInfo = JSON.parse(this.localStorage.getItem(this.shopcode+"_u"));
				this.user_tipus = this.userInfo.users_tipus;
			}
			else{
				this.user_tipus = 'privat';
			}
			if(this.localStorage.getItem(this.shopcode+"_kosar") != null){
				this.kosar_id = JSON.parse(this.localStorage.getItem(this.shopcode+"_kosar"));
			}
			for(let item of this.kosar_id){
				if(item.darab2 > 0){
					item.darab = item.darab2;
				}
				this.kosar_ossz = this.kosar_ossz + item.darab;
			}
		});
		this.commonService.face().subscribe((data: {}) => {
			this.face = data;
		});
		this.commonService.insta().subscribe((data: {}) => {
			this.insta = data;
		});
		this.commonService.getmodul(this.lang,"header2").subscribe((data: {}) => {
			this.header2 = data[0];
		});
		this.commonService.getmodul(this.lang,"center1").subscribe((data: {}) => {
			this.popup = data[0];
		});
		this.commonService.getmodul(this.lang,"cookie").subscribe((data: {}) => {
			this.cookie_text = data[0];
		});
		this.commonService.getword(this.lang,"kotelezo22-keres10").subscribe((data: {}) => {
			this.szavak = data;
		});
  	}
}
