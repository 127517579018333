<form [formGroup]="loginform" class="reg"(ngSubmit)="onSubmit()">
	<div class="reg_cont">
		<h1>Elfelejtett jelszó</h1>
		<div class="input_cont">
			<div class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
					<div *ngIf="f.email.errors.required">E-mail cím kötelező</div>
					<div *ngIf="f.email.errors.email">Hibás e-mail cím formátum</div>
				</div>
				<input type="email" formControlName="email" required placeholder="E-mail cím"/>
				<label>E-mail cím</label>
			</div>
		</div>
		<re-captcha [ngClass]="{'invalidated': submitted}" required formControlName="gcaptcha" (resolved)="resolved($event)" siteKey="6LdvVcUZAAAAABLCyf43sJvYw4KNrLjg-KdSx5Hq"></re-captcha>
		<button type="submit" name="kapcs_kuld"d class="kapcs_kuld" value="Küldés"><span>Küldés</span></button>
		<br/><br/><br/>
	</div>
</form>