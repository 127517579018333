import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { Router, RouterEvent, NavigationEnd} from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-lostpw',
  templateUrl: './lostpw.component.html',
  styleUrls: ['./lostpw.component.sass', '../reg/reg.component.sass']
})
export class LostpwComponent implements OnInit {

	siteurl: any;
	favicon: any;
	seotitle: any;
	lang: any = "hu";
	menu: any;
	token: any;
	submitted: any;
	hiba: any;
	error: any;
	shopcode: any;

	loginform = new FormGroup({
    	email: new FormControl('', [Validators.email]),
		gcaptcha: new FormControl('',[
			Validators.required
		]),
	});

	resolved(captchaResponse: string) {
    	this.token = captchaResponse;
	}

	onSubmit() {
		this.submitted = true;
		this.hiba = "";
		this.error = true;
    	Object.keys(this.loginform.controls).forEach(key => {
			if(this.loginform.controls[key].errors != null){
				this.error = false;
				if(key == "email"){
					if(this.loginform.controls[key].errors.required == true){
						//this.hiba = this.hiba + "E-mail cím kitöltése kötelező "
					}
					if(this.loginform.controls[key].errors.email == true){
						//this.hiba = this.hiba + "Hibás e-mail cím formátum "
					}
				}
				if(key == "gcaptcha"){
					if(this.loginform.controls[key].errors.required == true){
						this.hiba = this.hiba + "Captcha elfogadása kötelező "
					}
				}
			}
		});
    	if (this.error === false) {
			/*this.dialog.open(DialogComponent, {
				data: {
					title: 'Hiba',
					text: this.hiba,
					gomb: 'bezárás'
				}
			});*/ 
			return;
    	}
    	else{
			this.commonService.shopcode().subscribe((data: any) => {
				this.shopcode = data.shop_code;
      			this.userService.elpw(this.token, btoa(unescape(encodeURIComponent(JSON.stringify(this.loginform.value))))).subscribe((data: any) => {
					if(data['resp'] == true){
						this.router.navigate(["/sikeres-uj-jelszo-igenyles"]);
					}
					else{
						this.dialog.open(DialogComponent, {
							data: {
								title: data['resp'],
								gomb: 'bezárás'
							}
						});  
						return;
					}
				});
			});
    	}
  	}

	get f() { return this.loginform.controls; }
	
	constructor(public dialog: MatDialog, private router: Router, private userService: UserService, private commonService: CommonService) { }

  	ngOnInit(): void {
		this.commonService.siteurl().subscribe((data: any) => {
			this.siteurl = data.siteurl;
		});
		this.commonService.favicon().subscribe((data: any) => {
			this.favicon = data.favicon;
		});
		this.commonService.seotitle().subscribe((data: any) => {
			this.seotitle = data.title;
		});
		this.commonService.menuroww("54",this.lang).subscribe((data: {}) => {
			this.menu = data[0];
			let kepnev = this.favicon;
			let url = this.siteurl+this.menu.alias;
			this.commonService.seo(this.seotitle,'website',this.menu.seotitle,this.menu.desc,url,kepnev);
		});
	}

}
