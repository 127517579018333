import { Component, OnInit, ViewEncapsulation, Input, ViewChild} from '@angular/core';
import { ProdService } from '../../services/prod.service';
import { CommonService } from '../../services/common.service';
import { KosarService } from '../../services/kosar.service';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';
import {Router} from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { SyncService } from '../../services/sync.service';
import { DateAdapter, MatNativeDateModule, } from '@angular/material/core';
import { MatDatepickerModule, MatDatepicker } from '@angular/material/datepicker';
import { empty, of } from 'rxjs';
import { NumberFormatPipe } from '../../number.pipe';
import { DatePipe } from '@angular/common';
import { ThumbnailsMode } from '@ngx-gallery/core';

declare let ga: Function;

interface item {
	day: number;
	m: number;
}

interface term {
	id: number;
	darab: number;
}

@Component({
  	selector: 'app-kosar',
  	templateUrl: './kosar.component.html',
  	styleUrls: ['./kosar.component.sass', '../kapcsolat/kapcsolat.component.sass'],
	encapsulation: ViewEncapsulation.None,
	providers: [NumberFormatPipe, DatePipe]
})
export class KosarComponent implements OnInit {

	
	@ViewChild('picker',{static:false}) calendar: MatDatepicker<any>;
	setClass() {
		return (date: any) => {
		if (date.getDate() == 1) this.changeMonth(
			{month:date.getMonth()+1,
			year:date.getFullYear()
			});
		};
	}
	
	localStorage: any;  
	shopcode: any;
	kosar_id: any;
	lang: any = "hu";
	szavak: any;
	menu: any;
	siteurl: any;
	userInfo: any;
	szavak1: any;
	alias: any;
	uid: any;
	szam_szall_ua: any;
	szallmodlist: any;
	fizmodlist: any;
	szall_ki: any = false;
	submitted: any = false;
	naptar: any;
	select_dates: any;
	today: any;
	minDate: any;
	maxDate: any;
	szall_ar: any = 0;
	duplicates: any;
	ossz: any = 0;
	vegossz: any;
	adat_ny: any;
	aszf: any;
	hozza: any;
	token: any;
	ceg_szall_hide: any = true;
	login: any;
	reg: any;
	error: any = true;
	sikeres: any;
	favicon: any;
	seotitle: any;
	szall_ossz : any;
	szall_limit: boolean = true;
	szall_limit_ar: any;
	kizart: any;
	mod_deny: boolean = false;
	mod_deny2: boolean = false;
	type: any = "privat";
	kosar_id2: any;
	loading: any = false;
	type_int: any;

	constructor(private datePipe: DatePipe, private formatPipe: NumberFormatPipe, private dateAdapter: DateAdapter<Date>, public sync: SyncService, private meta: Meta, private title: Title, private router: Router, private dialog: MatDialog, private kosarService: KosarService, private prodService: ProdService, private commonService: CommonService) {
		this.localStorage = window.localStorage;
		this.dateAdapter.setLocale('hu');
		this.kosarService.kosar_ossz$.subscribe((kosar_ossz:any) => {
			this.ossz = kosar_ossz;
			this.vegossz = this.ossz + this.szall_ar;
		});
		this.kosarService.kosar_szall_ossz$.subscribe((kosar_szall_ossz:any) => {
			this.szall_ossz = kosar_szall_ossz;
		});
	}

	myDateFilter = (d: Date | null): boolean => {
		const day = (d || new Date()).getDay();
		// Prevent Saturday and Sunday from being selected.
		return day !== 0 && day !== 6;
	}

	ngOnInit(): void {
		let body = document.getElementsByTagName('body')[0];
    	body.classList.add('penztar');
		this.commonService.siteurl().subscribe((data: any) => {
			this.siteurl = data.siteurl;
		});
		this.commonService.favicon().subscribe((data: any) => {
			this.favicon = data.favicon;
		});
		this.commonService.seotitle().subscribe((data: any) => {
			this.seotitle = data.title;
		});

		this.commonService.menuroww("55-48-47-40-41-57",this.lang).subscribe((data: {}) => {
			this.menu = data[0];
			this.adat_ny = data[1];
			this.aszf = data[2];
			this.login = data[3];
			this.reg = data[4];
			this.sikeres = data[5];
			let kepnev = this.favicon;
			let url = this.siteurl+this.menu.alias;
			this.commonService.seo(this.seotitle,'website',this.menu.seotitle,this.menu.desc,url,kepnev);
		});
		this.commonService.getmodul(this.lang,"footer5").subscribe((data: {}) => {
			this.hozza = data[0];
		});
		this.commonService.getword(this.lang,"megrend_adatok-szall_ido-szall_select-date_valaszt-fizmod-szallitasimod-kosar_tartalma-szall_dij-ft-vegosszeg").subscribe((data: {}) => {
			this.szavak1 = data;
		});
		this.commonService.getword(this.lang,"magan-viszont-kotelezo-veznev-kernev-cegnev-kapcsnev-tel-email-pw-pw2-hibas-minkar-nagysz-ketj-adoszam-muke-tallozas-szamlazasi_cim-irsz-varos-utcahsz-szallitasi_cim-szallitasi_azonos_szamlazasival-cim-elfogadom2-reg").subscribe((data: {}) => {
			this.szavak = data;
		});
		this.commonService.shopcode().subscribe((data: any) => {
			this.shopcode = data.shop_code;
			if(this.localStorage.getItem(this.shopcode+"_kosar_ossz") != null){
				this.ossz = JSON.parse(this.localStorage.getItem(this.shopcode+"_kosar_ossz"));
				this.szall_ossz = JSON.parse(this.localStorage.getItem(this.shopcode+"_kosar_ossz"));
				this.vegossz = this.ossz;
			}
			if(this.localStorage.getItem(this.shopcode+"_u") != null){
				this.userInfo = JSON.parse(this.localStorage.getItem(this.shopcode+"_u"));
				if(this.userInfo.users_tipus === 'privat'){
					this.alias = "maganszemely";
					this.mod_deny2 = true;
					this.type = "privat";
					this.type_int = 0;
				}
				if(this.userInfo.users_tipus === 'ceg'){
					this.alias = "viszontelado";
					this.type = "ceg";
					this.mod_deny = true;
					this.type_int = 1;
				}
				this.kosarService.naptar(this.type_int).subscribe((data: any) => {
					this.naptar = data[0];
					this.kizart = data[1];
					let day = new Date().getDay();
					if(day > 0){
						day--;
					}
					this.today = this.naptar[day];
					this.minDate = new Date();
					this.maxDate = new Date();
					this.maxDate.setDate(this.maxDate.getDate() + 7); 
					//this.myDateFilter(new Date(),day);
				});
				this.uid = this.userInfo.users_id;
				this.addForm();
				this.regform.patchValue({
					users_id: this.userInfo.users_id,
					tel: this.userInfo.users_tel1,
					email: this.userInfo.users_email,
					szam_irsz: this.userInfo.users_irsz1,
					szam_varos: this.userInfo.users_telep1,
					szam_utca: this.userInfo.users_utca_hsz1,
					szall_1_cegnev: this.userInfo.szall_1_cegnev,
					szall_1_irsz: this.userInfo.szall_1_irsz,
					szall_1_varos: this.userInfo.szall_1_varos,
					szall_1_utca: this.userInfo.szall_1_utca,
					szall_1_utalas: this.userInfo.szall_1_utalas,
					szall_1_utalas_nap: this.userInfo.szall_1_utalas_nap,
					szall_2_cegnev: this.userInfo.szall_2_cegnev,
					szall_2_irsz: this.userInfo.szall_2_irsz,
					szall_2_varos: this.userInfo.szall_2_varos,
					szall_2_utca: this.userInfo.szall_2_utca,
					szall_2_utalas: this.userInfo.szall_2_utalas,
					szall_2_utalas_nap: this.userInfo.szall_2_utalas_nap,
					szall_3_cegnev: this.userInfo.szall_3_cegnev,
					szall_3_irsz: this.userInfo.szall_3_irsz,
					szall_3_varos: this.userInfo.szall_3_varos,
					szall_3_utca: this.userInfo.szall_3_utca,
					szall_3_utalas: this.userInfo.szall_3_utalas,
					szall_3_utalas_nap: this.userInfo.szall_3_utalas_nap,
					szall_4_cegnev: this.userInfo.szall_4_cegnev,
					szall_4_irsz: this.userInfo.szall_4_irsz,
					szall_4_varos: this.userInfo.szall_4_varos,
					szall_4_utca: this.userInfo.szall_4_utca,
					szall_4_utalas: this.userInfo.szall_4_utalas,
					szall_4_utalas_nap: this.userInfo.szall_4_utalas_nap,
					szall_5_cegnev: this.userInfo.szall_5_cegnev,
					szall_5_irsz: this.userInfo.szall_5_irsz,
					szall_5_varos: this.userInfo.szall_5_varos,
					szall_5_utca: this.userInfo.szall_5_utca,
					szall_5_utalas: this.userInfo.szall_5_utalas,
					szall_5_utalas_nap: this.userInfo.szall_5_utalas_nap,
					veznev: this.userInfo.users_vnev1,
					kernev: this.userInfo.users_knev1,
					szam_veznev: this.userInfo.users_vnev2,
					szam_kernev: this.userInfo.users_knev2,
					szall_veznev: this.userInfo.users_vnev3,
					szall_kernev: this.userInfo.users_knev3,
					szall_irsz: this.userInfo.users_irsz2,
					szall_varos: this.userInfo.users_telep2,
					szall_utca: this.userInfo.users_utca_hsz2,
					cegnev: this.userInfo.users_cegnev1,
					kapcs: this.userInfo.users_kapcs_tart,
					adoszam: this.userInfo.users_ado,
					szam_ado: this.userInfo.users_ado2,
					szam_cegnev: this.userInfo.users_cegnev2
				});
			}
			if(this.localStorage.getItem(this.shopcode+"_kosar") != null){
				this.kosar_id = JSON.parse(this.localStorage.getItem(this.shopcode+"_kosar"));
				this.szall_ossz = 0;
				for(let item of this.kosar_id){
					this.prodService.prodkat(item.termek_termekek_id, this.lang).subscribe((data2: any) => {
						item.szint1_title = data2.szint1_title;
					});
					/*if(this.type === 'ceg'){
						if(item.ara_ceg != '' || item.ara_ceg > 0){
							item.brutto_ar = Number(item.ara_ceg);
						}
						else{
							for(let item2 of item.arak){
								if(item.darab >= item2.db){
									item.brutto_ar = item2.ar;
								}
							}
						}
					}
					if(this.type === 'privat'){
						if(item.lak_akcio != '' || item.lak_akcio > 0){
							item.brutto_ar = Number(item.lak_akcio);
						}
						if(item.lak_akcio === '' || item.lak_akcio === 0){
							item.brutto_ar = Number(item.lak_ar);
						}
					}*/
					item.osszesen = item.brutto_ar * item.darab;
					if(item.termek_termekek_kisz_belesz === 1){
						this.szall_ossz = this.szall_ossz + item.osszesen;
					}
				}
				console.log(this.szall_ossz);
			}
			this.kosarService.getszallmod(this.lang).subscribe((data: {}) => {
				this.szallmodlist = data;
			});
			this.kosarService.getfizmod(this.lang).subscribe((data: {}) => {
				this.fizmodlist = data;
			});
		});
	}

	regform = new FormGroup({
	  	files: new FormControl('', []),
	  	users_id: new FormControl('', []),
	  	szall_kernev: new FormControl('', []),
	 	tel: new FormControl('', [Validators.required]),
	  	email: new FormControl('', [Validators.email,Validators.required]),
	  	szam_irsz: new FormControl('', [Validators.required]),
	  	szam_varos: new FormControl('', [Validators.required]),
	  	szam_utca: new FormControl('', [Validators.required]),
		szall_1_cegnev: new FormControl('', []),
		szall_1_irsz: new FormControl('', []),
		szall_1_varos: new FormControl('', []),
		szall_1_utca: new FormControl('', []),
		szall_1_mukodes: new FormControl('', []),
		szall_1_mukodes_src: new FormControl('', []),
		szall_1_utalas: new FormControl('', []),
		szall_1_utalas_nap: new FormControl('', []),
		szall_2_cegnev: new FormControl('', []),
		szall_2_irsz: new FormControl('', []),
		szall_2_varos: new FormControl('', []),
		szall_2_utca: new FormControl('', []),
		szall_2_mukodes: new FormControl('', []),
		szall_2_mukodes_src: new FormControl('', []),
		szall_2_utalas: new FormControl('', []),
		szall_2_utalas_nap: new FormControl('', []),
		szall_3_cegnev: new FormControl('', []),
		szall_3_irsz: new FormControl('', []),
		szall_3_varos: new FormControl('', []),
		szall_3_utca: new FormControl('', []),
		szall_3_mukodes: new FormControl('', []),
		szall_3_mukodes_src: new FormControl('', []),
		szall_3_utalas: new FormControl('', []),
		szall_3_utalas_nap: new FormControl('', []),
		szall_4_cegnev: new FormControl('', []),
		szall_4_irsz: new FormControl('', []),
		szall_4_varos: new FormControl('', []),
		szall_4_utca: new FormControl('', []),
		szall_4_mukodes: new FormControl('', []),
		szall_4_mukodes_src: new FormControl('', []),
		szall_4_utalas: new FormControl('', []),
		szall_4_utalas_nap: new FormControl('', []),
		szall_5_cegnev: new FormControl('', []),
		szall_5_irsz: new FormControl('', []),
		szall_5_varos: new FormControl('', []),
		szall_5_utca: new FormControl('', []),
		szall_5_mukodes: new FormControl('', []),
		szall_5_mukodes_src: new FormControl('', []),
		szall_5_utalas: new FormControl('', []),
		szall_5_utalas_nap: new FormControl('', []),
		szall_utalas: new FormControl('', []),
		szall_utalas_nap: new FormControl('', []),
		fizmod: new FormControl('', [Validators.required]),
		szallmod: new FormControl('', [Validators.required]),
		szall_irsz: new FormControl('', [Validators.required]),
		szall_varos: new FormControl('', [Validators.required]),
		szall_utca: new FormControl('', [Validators.required]),
		megjegyzes: new FormControl('', []),
		kapcsa: new FormControl('', [Validators.required]),
		hozzaj: new FormControl('', []),
		aszf: new FormControl('', [Validators.required]),
		any: new FormControl('', [Validators.required]),
	});

	addForm(){
		if(this.alias === 'maganszemely'){
			this.regform.registerControl('veznev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('kernev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szam_veznev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szam_kernev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szall_veznev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szall_kernev',new FormControl('', [
				Validators.required
			]));
		}
		if(this.alias === 'viszontelado'){
			this.regform.addControl('cegnev',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('ceg_szall_cim',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('kapcs',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('szall_cegnev',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('adoszam',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('szall_0_mukodes',new FormControl('', [
				
			]));
			this.regform.addControl('szall_0_mukodes_src',new FormControl('', [
				
			]));
			this.regform.addControl('szam_ado',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('szam_cegnev',new FormControl('', [
				Validators.required
			]));
		}
	}

	get f() { return this.regform.controls; }

	egyezik(){
		if(this.szam_szall_ua === false){
			this.szam_szall_ua = true;
			if(this.alias === "maganszemely"){
				this.regform.controls.szall_kernev.setValue(this.regform.controls.szam_kernev.value);
				this.regform.controls.szall_veznev.setValue(this.regform.controls.szam_veznev.value);
				this.regform.controls.szall_irsz.setValue(this.regform.controls.szam_irsz.value);
				this.regform.controls.szall_varos.setValue(this.regform.controls.szam_varos.value);
				this.regform.controls.szall_utca.setValue(this.regform.controls.szam_utca.value);
			}
		}
		else{
			this.szam_szall_ua = false;
		}
	}

	cegSzall(){
		let id = this.regform.controls.ceg_szall_cim.value;
		switch(id) { 
			case '1': { 
				this.regform.controls.szall_cegnev.setValue(this.regform.controls.szall_1_cegnev.value);
				this.regform.controls.szall_irsz.setValue(this.regform.controls.szall_1_irsz.value);
				this.regform.controls.szall_varos.setValue(this.regform.controls.szall_1_varos.value);
				this.regform.controls.szall_utca.setValue(this.regform.controls.szall_1_utca.value);
				this.regform.controls.szall_utalas.setValue(this.regform.controls.szall_1_utalas.value);
				this.regform.controls.szall_utalas_nap.setValue(this.regform.controls.szall_1_utalas_nap.value);
				break; 
			}
			case '2': { 
				this.regform.controls.szall_cegnev.setValue(this.regform.controls.szall_2_cegnev.value);
				this.regform.controls.szall_irsz.setValue(this.regform.controls.szall_2_irsz.value);
				this.regform.controls.szall_varos.setValue(this.regform.controls.szall_2_varos.value);
				this.regform.controls.szall_utca.setValue(this.regform.controls.szall_2_utca.value);
				this.regform.controls.szall_utalas.setValue(this.regform.controls.szall_2_utalas.value);
				this.regform.controls.szall_utalas_nap.setValue(this.regform.controls.szall_2_utalas_nap.value);
				break; 
			}
			case '3': { 
				this.regform.controls.szall_cegnev.setValue(this.regform.controls.szall_3_cegnev.value);
				this.regform.controls.szall_irsz.setValue(this.regform.controls.szall_3_irsz.value);
				this.regform.controls.szall_varos.setValue(this.regform.controls.szall_3_varos.value);
				this.regform.controls.szall_utca.setValue(this.regform.controls.szall_3_utca.value);
				this.regform.controls.szall_utalas.setValue(this.regform.controls.szall_3_utalas.value);
				this.regform.controls.szall_utalas_nap.setValue(this.regform.controls.szall_3_utalas_nap.value);
				break; 
			}
			case '4': { 
				this.regform.controls.szall_cegnev.setValue(this.regform.controls.szall_4_cegnev.value);
				this.regform.controls.szall_irsz.setValue(this.regform.controls.szall_4_irsz.value);
				this.regform.controls.szall_varos.setValue(this.regform.controls.szall_4_varos.value);
				this.regform.controls.szall_utca.setValue(this.regform.controls.szall_4_utca.value);
				this.regform.controls.szall_utalas.setValue(this.regform.controls.szall_4_utalas.value);
				this.regform.controls.szall_utalas_nap.setValue(this.regform.controls.szall_4_utalas_nap.value);
				break; 
			}
			case '5': { 
				this.regform.controls.szall_cegnev.setValue(this.regform.controls.szall_5_cegnev.value);
				this.regform.controls.szall_irsz.setValue(this.regform.controls.szall_5_irsz.value);
				this.regform.controls.szall_varos.setValue(this.regform.controls.szall_5_varos.value);
				this.regform.controls.szall_utca.setValue(this.regform.controls.szall_5_utca.value);
				this.regform.controls.szall_utalas.setValue(this.regform.controls.szall_5_utalas.value);
				this.regform.controls.szall_utalas_nap.setValue(this.regform.controls.szall_5_utalas_nap.value);
				break; 
			}
		}
		this.ceg_szall_hide = false;
	}

	async delay(ms: number) {
		await new Promise(resolve => setTimeout(()=>resolve(), ms)).then(()=>this.empty());
	}

	empty(){

	}

	changeMonth(date){
		this.changeDate();
	}

	datePrice(){
		let newdate = this.regform.controls.szall_time.value
		//this.regform.controls.szall_time.setValue(this.datePipe.transform(newdate, 'yyy-MM-dd'));
		let day = newdate.getDay();
		let nap: any;
		//day--;
		if(day != 6){
			nap = this.naptar[0];
		}
		else{
			nap = this.naptar[5];
		}
		if(this.userInfo.users_tipus === 'privat'){
			if(this.szall_ossz < nap.magan){
				this.szall_limit = false;
				this.regform.controls.szall_time.setValue("");
			}
			else{
				this.szall_limit = true;
				for(let item of nap.magan_arak){
					if(item.tol <= this.szall_ossz && this.szall_ossz <= item.ig){
						console.log(item);
						this.szall_ar = item.ar;
					}
				}
			}
			this.szall_limit_ar = nap.magan;
		}
		if(this.userInfo.users_tipus === 'ceg'){
			if(this.szall_ossz < nap.ceg){
				this.szall_limit = false;
				this.regform.controls.szall_time.setValue("");
			}
			else{
				this.szall_limit = true;
				for(let item of nap.ceges_arak){
					if(item.tol <= this.szall_ossz && this.szall_ossz <= item.ig){
						console.log(item);
						this.szall_ar = item.ar;
					}
				}
			}
			this.szall_limit_ar = nap.ceg;
		}
		console.log(this.szall_ar);
		if(this.szall_limit === false){
			this.dialog.open(DialogComponent, {
				data: {
					title: 'Hiba',
					text: 'A kosár összege nem éri el az adott napra megszabott minimális rendelési összeget: '+this.szall_limit_ar,
					gomb: 'bezárás'
				}
			});
		}
		else{
			this.vegossz = this.ossz + this.szall_ar;
		}
	}

	getDay(cell){
		let re = /\./gi;
		let celldate = cell['attributes']['aria-label']['nodeValue'].replace(re, "");
		celldate = celldate.split(" ");
		let m = 0;
		switch(celldate[1]) { 
			case 'január': { 
				m = 1; 
				break; 
			}
			case 'február': { 
				m = 2; 
				break; 
			}
			case 'március': { 
				m = 3; 
				break; 
			}
			case 'április': { 
				m = 4; 
				break; 
			}
			case 'május': { 
				m = 5; 
				break; 
			} 
			case 'június': { 
				m = 6; 
				break; 
			} 
			case 'július': { 
				m = 7; 
				break; 
			} 
			case 'augusztus': { 
				m = 8; 
				break; 
			}
			case 'szeptember': { 
				m = 9; 
				break; 
			}  
			case 'október': { 
				m = 10; 
				break; 
			} 
			case 'november': { 
				m = 11; 
				break; 
			} 
			case 'december': { 
				m = 12; 
				break; 
			} 
		}
		m--;
		let newcell = celldate[0]+"-"+m+"-"+celldate[2];
		let newdate = new Date(celldate[0],m,celldate[2]);
		//console.log(newdate);
		let day = newdate.getDay();
		let result: item = {
			day: day,
			m: Number(m)
		};
		return result;
	}

	changeDate() {
		let body = document.getElementsByTagName('body')[0];
		body.classList.remove('szall_open');
		console.log("change start");
		let cells = document.getElementsByClassName('mat-calendar-body-cell');
		let current_cell = document.getElementsByClassName('mat-calendar-body-active');
		this.delay(1000).then(any => {
			let rightnow = new Date();
			let current_time = this.today.ora;
			let current_year = rightnow.getFullYear();
			let current_month = rightnow.getMonth() + 1;
			let current_day = rightnow.getDay();
			let current = new Date(current_year + "-" + current_month + "-" + current_day + " " + current_time);

			if (current_cell.length > 0) {
				let active_day = this.getDay(current_cell[0]);

				if (rightnow > current && current_day === active_day.day) {
					if (active_day.m === current_month) {
						current_cell[0].setAttribute('id', 'sndy');
						current_cell[0].classList.add('mat-calendar-body-disabled');
						current_cell[0].setAttribute('aria-disabled', 'true');
						current_cell[0].setAttribute('aria-selected', 'false');
						//current_cell[0].classList.remove('mat-calendar-body-active');
						//current_cell[0].classList.remove('mat-focus-indicator');
					}
				}
			}
			let naptarIds = Array();
			if(this.naptar[0].aktiv === 1){
				naptarIds.push(this.naptar[0].id);
			}
			if(this.naptar[0].nap_k === 1){
				naptarIds.push(this.naptar[1].id);
			}
			if(this.naptar[0].nap_sz === 1){
				naptarIds.push(this.naptar[2].id);
			}
			if(this.naptar[0].nap_cs === 1){
				naptarIds.push(this.naptar[3].id);
			}
			if(this.naptar[0].nap_p === 1){
				naptarIds.push(this.naptar[4].id);
			}
			if(this.naptar[5].aktiv === 1){
				naptarIds.push(this.naptar[5].id);
			}
			for (let i = 0; i < cells.length; i++){
				console.log("counting");
				let aria_date =  cells[i].getAttribute('aria-label').split(" ");
				let aria_year = aria_date[0].slice(0, -1);
				let aria_month = this.getMonth(aria_date[1]);
				let aria_day_num = Number(aria_date[2].slice(0, -1));
				let aria_day_string = aria_date[2].slice(0, -1);
				let aria_day = "";
				if(aria_day_num < 10){
					aria_day = "0"+aria_day_string;
				}
				else{
					aria_day = aria_day_string;
				}
				let cell_date = aria_year+"-"+aria_month+"-"+aria_day;
				if(this.kizart.find(x => x.datum === cell_date)){
					cells[i].classList.add('mat-calendar-body-disabled');
					cells[i].setAttribute('aria-disabled','true');
					cells[i].setAttribute('aria-selected','false');
					cells[i].classList.remove('mat-calendar-body-active');
					cells[i].classList.remove('mat-focus-indicator');
				}
				let day = this.getDay(cells[i]);
				for(let u = 0; u<naptarIds.length;u++){
					//alert(naptarIds[u]);
				}
				if(naptarIds.indexOf(day.day) > -1){

				}
				else{
					cells[i].setAttribute('id','sndy');
					cells[i].classList.add('mat-calendar-body-disabled');
					cells[i].setAttribute('aria-disabled','true');
					cells[i].setAttribute('aria-selected','false');
					cells[i].classList.remove('mat-calendar-body-active');
					cells[i].classList.remove('mat-focus-indicator');
				}
				if(day.day === 0){
					cells[i].setAttribute('id','sndy');
					cells[i].classList.add('mat-calendar-body-disabled');
					cells[i].setAttribute('aria-disabled','true');
					cells[i].setAttribute('aria-selected','false');
					cells[i].classList.remove('mat-calendar-body-active');
					cells[i].classList.remove('mat-focus-indicator');
				}
			}
			this.delay(1000).then(any => {
				body.classList.add('szall_open');
				console.log("change end");
			});
	   });
	}

	getMonth(MonthYear) {
		var months = [
		  'január',
		  'február',
		  'március',
		  'április',
		  'május',
		  'június',
		  'július',
		  'augusztus',
		  'szeptember',
		  'október',
		  'november',
		  'december'
		];
		var i = months.indexOf(MonthYear) + 1;
		var month = (months.indexOf(MonthYear) + 1);     
		if(i < 10){
			return "0"+month;
		}
		else{
			return month;
		}
	}

	checkSzall(ar, id){
		if(id === 8){
			this.regform.addControl('szall_time',new FormControl('', [
				Validators.required	
			]));
			this.szall_ki = true;
		}
		else{
			this.szall_ki = false;
			this.szall_ar = 0;
			this.vegossz = this.ossz + this.szall_ar;
			this.regform.removeControl('szall_time');
		}
	}
	  
	onSubmit() {
		this.submitted = true;
		this.error = true;
		Object.keys(this.regform.controls).forEach(key => {
			if(this.regform.controls[key].errors != null){
				this.error = false;
			}
		});
		if(this.regform.controls['fizmod'].value === ''){
			this.dialog.open(DialogComponent, {
				data: {
					title: 'Hiba',
					text: 'Fizetési mód választása kötelező',
					gomb: 'bezárás'
				}
			});
		}
		if(this.regform.controls['szallmod'].value === ''){
			this.dialog.open(DialogComponent, {
				data: {
					title: 'Hiba',
					text: 'Szállítási mód választása kötelező',
					gomb: 'bezárás'
				}
			});
		}
		if (this.error === false) {
			return;
		}
		else{
			if(this.szall_limit === false){
				this.dialog.open(DialogComponent, {
					data: {
						title: 'Hiba',
						text: 'A kosár összege nem éri el az adott napra megszabott minimális rendelési összeget: '+this.szall_limit_ar,
						gomb: 'bezárás'
					}
				});
			}
			else{
				this.loading = true;
				this.commonService.shopcode().subscribe((data: any) => {
					this.shopcode = data.shop_code;
					let k = Array();
					for(let item of this.kosar_id){
						let r: term = {
							id: item.termek_termekek_id,
							darab: item.darab
						};
						k.push(r);
					}
					console.log(this.szall_ar);
					this.kosarService.rend(this.szall_ar,JSON.stringify(k).toString(),JSON.stringify(this.regform.value).toString(),this.lang, this.alias).subscribe((data: any) => {
						console.log(data);
						if(data.resp === "faildate" || data.resp == "early"){
							this.loading = false;
							if(data.resp == "early"){
								this.dialog.open(DialogComponent, {
									data: {
										title: 'Hiba',
										text: 'Mai napra már nem tudunk kiszállítást vállalni',
										gomb: 'bezárás'
									}
								});
							}
							if(data.resp === "faildate" ){
								this.dialog.open(DialogComponent, {
									data: {
										title: 'Hiba',
										text: 'Kérem válasszon másik szállítási időpontot',
										gomb: 'bezárás'
									}
								});
							}
						}
						else{
							if(data.resp === "failuser" ){
								this.dialog.open(DialogComponent, {
									data: {
										title: 'Hiba',
										text: 'Sikertelen megrendelés, próbálja újra böngésző cache űrítés után: https://support.google.com/accounts/answer/32050?hl=hu&co=GENIE.Platform%3DDesktop',
										gomb: 'bezárás'
									}
								});
							}
							else{
								if(this.localStorage.getItem(this.shopcode+"_kosar") != null){
									let t_id = this.prodService.getRandom(12);
									ga('ecommerce:addTransaction', {
										'id': t_id,                     // Transaction ID. Required.
										'affiliation': 'Tassi és Társa Kft. - Ital Kis- és Nagykereskedés',   // Affiliation or store name.
										'revenue': this.vegossz,               // Grand Total.
										'shipping': '',                  // Shipping.
										'tax': ''                     // Tax.
									});
									let kosar = JSON.parse(this.localStorage.getItem(this.shopcode+"_kosar"));
									let n = this.kosar_id.length;
									let i = 0;
									for(let item of this.kosar_id){
										let ar = 0;
										if(this.type = 'privat'){
											if(item.lak_akcio > 0){
												ar = item.lak_akcio;
											}
											else{
												ar = item.lak_ar;
											}
										}
										else{
											if(item.ara_ceg > 0){
												ar = item.ara_ceg;
											}
											else{
												ar = item.ara_magan;
											}
										}
										ga('ecommerce:addItem', {
											'id': item.termek_termekek_id,                     // Transaction ID. Required.
											'name': item.termek_termekek_nev,    // Product name. Required.
											'sku': item.cikkszam,                 // SKU/code.
											'category': item.szint1_title,         // Category or variation.
											'price': ar,                 // Unit price.
											'quantity': item.darab                   // Quantity.
										});
										i++;
									}
									if(n === i){
										ga('ecommerce:send');
										//ga('ecommerce:clear');
										this.localStorage.removeItem(this.shopcode+"_kosar_ossz");
										this.localStorage.removeItem(this.shopcode+"_kosar_szall_ossz");
										this.localStorage.removeItem(this.shopcode+"_kosar");
										this.kosarService.set_kosar_id(0);
										this.kosarService.set_kosar_ossz(0);
										this.kosarService.set_kosar_szall_ossz(0);
										this.loading = false;
										this.router.navigate([this.sikeres.alias]);
									}
								}
							}
						}
					});
				});
			}
		}
	}

	resolved(captchaResponse: string) {
		this.token = captchaResponse;
	}
}